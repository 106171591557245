<template lang="pug">
div
  page-header(:title='title', :url='url', :titleNew='titleNew')
  .row
    .col-md-3
      label Periodo:
      br
      date-range-picker(:opens='opens', @update='updateValues', :locale-data='locale', :startDate='startDate', :endDate='endDate')
    .col-md-3
      label Modificações:
      input.form-control(v-model="filtro.modificacoes", placeholder="Modificações")
    .col-md-3
      label Menu:
      input.form-control(v-model="filtro.menu", placeholder="Menu")
    .col-md-3
      select-form(v-model='filtro.usuario', :value='filtro.usuario', name='Usuário', url='/usuarios', id='id', titulo='name')
  br
  grid(:url='urlDataSource', :columns='columns', :source='source', :grid='grid', :page='15')
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'
import SelectForm from '@/components/form/SelectForm'
import DateRangePicker from '@/components/form/DateRangePicker/DateRangePicker'

import GridTemplate from '@/mixins/GridTemplate'

export default {
  mixins: [GridTemplate],
  components: {
    PageHeader,
    Grid,
    SelectForm,
    DateRangePicker
  },
  computed: {
    ...mapGetters(['user']),
    urlDataSource() {
      return this.user.id_grupo === '1'
        ? `${this.$url}/logs`
        : `${this.$url}/logs/${this.user.id}`
    },
    filter() {
      return {
        logic: 'and',
        filters: [
          {
            field: 'periodo',
            operator: 'contains',
            value: this.filtro.dataini
              ? this.filtro.dataini
              : moment()
                  .startOf('day')
                  .startOf('month')
                  .format('YYYY-MM-DD'),
            value2: this.filtro.datafim
              ? this.filtro.datafim
              : moment().format('YYYY-MM-DD')
          },
          {
            field: 'modificacoes',
            operator: 'contains',
            value: this.filtro.modificacoes
          },
          {
            field: 'menu',
            operator: 'contains',
            value: this.filtro.menu
          },
          {
            field: 'usuario',
            operator: 'contains',
            value: this.filtro.usuario
          }
        ]
      }
    },
    source() {
      return [
        { filter: this.filter },
        { serverFiltering: true },
        { schemaData: 'data' },
        { pageSize: 15 },
        { serverPaging: true },
        { schemaTotal: 'total' }
      ]
      /* return [
        { filter: this.filter },
        { serverFiltering: true },
        { schemaData: 'data' },
        { schemaTotal: 'total' },
        { pageSize: 15 },
        { serverPaging: true }
      ] */
    }
  },
  data() {
    return {
      title: 'Logs',
      titleNew: '',
      url: '/logs/logs/',
      grid: {
        pageable: true,
        pageableAlwaysVisible: false,
        pageablePageSizes: [5, 10, 20, 100],
        height: 500
      },
      filtro: [
        {
          dataini: moment()
            .startOf('day')
            .startOf('month')
            .format('YYYY-MM-DD'),
          datafim: moment().format('YYYY-MM-DD'),
          modificacoes: '',
          menu: '',
          usuario: ''
        }
      ],
      startDate: moment()
        .startOf('day')
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment(),
      opens: 'ltr',
      locale: {
        direction: 'ltr',
        format: 'DD/MM/YYYY',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1,
        open: true
      },
      columns: [
        {
          field: 'description',
          title: 'Descrição',
          width: 120
        },
        {
          field: 'log_name',
          title: 'Menu',
          width: 120
        },
        {
          field: 'subject',
          title: 'Atual',
          template: this.templateSubject,
          width: 300
        },
        {
          field: 'causer.name',
          title: 'Usuário',
          width: 150
        },
        {
          field: 'properties',
          title: 'Modificações',
          template: this.templateUsuario,
          width: 300
        },
        {
          field: 'created_at',
          title: 'Data',
          width: 100
        }
      ]
    }
  },
  methods: {
    updateValues(values) {
      this.filtro.dataini = moment(values.startDate).format('YYYY-MM-DD')
      this.filtro.datafim = moment(values.endDate).format('YYYY-MM-DD')
    },
    verifPermissao() {
      this.titleNew = this.ACTION.c ? 'Novo' : ''
      if (this.ACTION.e) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    },
    templateUsuario(e) {
      return {
        template: Vue.component('temp', {
          template: `<ul>
            <li v-for='(i, k) in templateArgs.properties'>
              {{k}}: <ul>
                        <li v-for='(ia, ka) in templateArgs.properties[k]'>
                          {{ka}}: {{templateArgs.properties[k][ka]}}
                        </li>
                    </ul>
            </li>
          </ul>`,
          data() {
            return {
              templateArgs: {}
            }
          }
        }),
        templateArgs: JSON.parse(JSON.stringify(e))
      }
    },
    templateSubject(e) {
      return {
        template: Vue.component('temp', {
          template: `<ul>
            <li v-for='(i, k) in templateArgs.subject'>
              {{k}}: {{templateArgs.subject[k]}}
            </li>
          </ul>`,
          data() {
            return {
              templateArgs: {}
            }
          }
        }),
        templateArgs: JSON.parse(JSON.stringify(e))
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
